const customdomain = {
    computed: {
        isMainProject() {
            if (
                this.$config.disbaleCustomDomains &&
                !this.$config?.forcedCustomDomain
            )
                return true
            let hostname = ""
            if (this.$config?.forcedCustomDomain) {
                hostname = this.$config?.forcedCustomDomain.replace("www.", "")
            } else if (process.server) {
                hostname = this.$ssrContext.req.headers.host.replace("www.", "")
            } else {
                hostname = location.host.replace("www.", "")
            }
            const baseUrl = this.$config.baseURL || "http://localhost"
            return baseUrl.includes(hostname)
        },
        baseUrl() {
            if (this.isMainProject) return this.$config.baseURL
            let hostname = ""
            if (this.$config?.forcedCustomDomain) {
                hostname = this.$config?.forcedCustomDomain.replace("www.", "")
            } else if (process.server) {
                hostname = this.$ssrContext.req.headers.host.replace("www.", "")
            } else {
                hostname = location.host.replace("www.", "")
            }
            return `https://${hostname}`
        },
    },
}

export default customdomain
