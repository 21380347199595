
export default {
    name: "SkeletonCard",
    props: {
        /** card's height */
        height: {
            type: Number,
            default: 450,
            validator: (val) => val >= 0,
        },
        /** card's width */
        width: {
            type: Number,
            default: 350,
            validator: (val) => val >= 0,
        },
        dark: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        style() {
            return {
                width: `100%`,
                maxWidth: `${this.width}px`,
                height: `${this.height}px`,
            }
        },
    },
}
