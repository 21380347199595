export default {
    data: () => ({
        observer: null,
    }),
    methods: {
        /**
         * Creates an observer and observes entries for intersections
         * @param entries entries to be observed [Array, Object]
         * @param callbackTrue the function to be executed if intersecting
         * @param callbackFalse the function to be executed if NOT intersecting
         * @param options options for the observer
         * */
        observeIntersection(
            entries,
            callbackTrue,
            callbackFalse = null,
            options = {}
        ) {
            this.observer = new IntersectionObserver((ents) => {
                ents.forEach((ent) => {
                    // if callbackFalse is not defined, use empty function
                    if (ent.isIntersecting) {
                        callbackTrue(ent)
                    } else {
                        callbackFalse ? callbackFalse(ent) : () => {}
                    }
                })
            }, options)
            if (Array.isArray(entries))
                entries.forEach((i) => {
                    if (i instanceof Element) {
                        this.observer.observe(i)
                    } else {
                        console.error("i is not of type Element", i)
                    }
                })
            // Should be a DOM element
            else if (entries instanceof Element) this.observer.observe(entries)
        },
        /** Unobserve all target entries */
        unobserveAll() {
            this.observer?.disconnect()
        },
    },
    beforeDestroy() {
        this.observer?.disconnect()
        this.observer = null
    },
}
