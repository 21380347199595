export default {
    inject: {
        schedule: {
            default: () => [],
        },
        jobFairName: {
            default: null,
        },
    },
    provide() {
        return {
            schedule: this.schedule,
            jobFairName: this.jobFairName,
        }
    },
    computed: {
        jobFairActive() {
            return this.schedule && this.schedule.length > 0
        },
    },
}
