
import intersectionObserver from "@/mixins/intersectionObserver"

export default {
    name: "LazyViewportRender",
    mixins: [intersectionObserver],
    props: {
        observerMargin: {
            type: String,
            default: "1200px",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        displayComponent: true,
        placeholderHeight: 0,
        placeholderWidth: 0,
    }),
    watch: {
        disabled: {
            handler(val) {
                if (val) {
                    this.disableObserver()
                } else {
                    this.enableObserver()
                }
            },
        },
    },
    mounted() {
        if (!this.disabled) {
            this.enableObserver()
        }
    },
    activated() {
        if (!this.disabled) {
            // timeout needed to wait for the child component to be active
            setTimeout(() => {
                this.enableObserver()
            }, 300)
        }
    },
    deactivated() {
        if (!this.disabled) {
            this.disableObserver()
        }
    },
    beforeDestroy() {
        if (!this.disabled) {
            this.disableObserver()
        }
    },
    methods: {
        enableObserver() {
            this.$nextTick(() => {
                const renderer = this.$refs.renderer

                this.observeIntersection(
                    renderer,
                    () => {
                        this.displayComponent = true
                    },
                    () => {
                        this.placeholderHeight = renderer.clientHeight
                        this.placeholderWidth = renderer.clientWidth
                        this.displayComponent = false
                    },
                    { rootMargin: this.observerMargin }
                )
            })
        },
        disableObserver() {
            this.unobserveAll()
        },
    },
}
