
import SkeletonCard from "@/components/search/v2/JobCardSkeleton"
import customdomain from "@/mixins/customdomain"
import jobFair from "@/mixins/customdomain/jobFair"
import LazyViewportRender from "@/components/base/LazyViewportRender"

export default {
    name: "CardGridDynamic",
    components: {
        LazyViewportRender,
        SkeletonCard,
        BookSlotButton: () => import("@/components/jobfair/BookSlotButton"),
    },
    emits: ["fetch"],
    mixins: [customdomain, jobFair],
    props: {
        targetBlank: {
            type: Boolean,
            default: false,
        },
        embedMode: {
            type: Boolean,
            default: false,
        },
        /** list of items  */
        items: {
            type: Array,
            default: () => [],
        },
        /**
         * item component type
         * @values job, business
         * @default job
         */
        itemType: {
            type: String,
            default: "job",
            validator: (val) => ["job", "business", "post"].includes(val),
        },
        /** item's height */
        height: {
            type: Number,
            default: 450,
            validator: (val) => val >= 0,
        },
        /** item's width */
        width: {
            type: Number,
            default: 350,
            validator: (val) => val >= 0,
        },
        /** is item's width fixed (cannot shrink) */
        fixedWidth: {
            type: Boolean,
            default: false,
        },
        /** grid gap between rows and columns */
        gridGap: {
            type: Number,
            default: 20,
            validator: (val) => val >= 0,
        },
        /** show skeleton loader */
        loading: {
            type: Boolean,
            default: false,
        },
        /** number of skeleton loader cards */
        loadingItems: {
            type: Number,
            default: 12,
        },
        /** Sets intesection handler for fetching more elements */
        intersectionHandler: {
            type: Function,
            default: undefined,
        },
        /** max number of rows */
        rows: {
            type: Number,
            default: undefined,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        totalNumberOfItems: {
            type: Number,
            default: undefined,
        },
    },
    data: () => ({
        numberOfCols: 0,
    }),
    computed: {
        numberOfBufferCards() {
            if (!this.totalNumberOfItems || !this.showSkeletonIntersector)
                return 0
            return Math.min(
                Math.max(this.totalNumberOfItems - this.items?.length - 1, 0),
                12
            )
        },
        visibleItems() {
            if (this.loading) return this.loadingItems
            if (this.numberOfCols === 0) return this.items //Return full list on SSR for SEO
            if (!this.rows) return this.items
            return this.items.slice(0, this.rows * this.numberOfCols)
        },
        component() {
            switch (this.itemType) {
                case "job":
                    return () => import("@/components/job/card/JobCard")
                case "business":
                    return () => import("@/components/business/BusinessCard")
                case "post":
                    return () => import("@/components/post/PostCard")
                default:
                    return null
            }
        },
        /** styles of the wrapper component */
        wrapperStyles() {
            const styles = {
                gap: `${this.gridGap}px`,
            }
            if (!this.rows) {
                return styles
            }
            return {
                ...styles,
                maxWidth: `${
                    this.rows * (this.width + this.gridGap) - this.gridGap
                }px`,
            }
        },
        showIntersector() {
            if (this.loading) return false
            if (!this.intersectionHandler) return false
            if (this.totalNumberOfItems) return false
            if (!this.rows) return true
            if (!this.numberOfCols) return true
            return this.items.length < this.rows * this.numberOfCols
        },
        showSkeletonIntersector() {
            if (!this.totalNumberOfItems) return false
            if (
                !!this.totalNumberOfItems &&
                this.totalNumberOfItems - this.items?.length <= 0
            )
                return false
            return true
        },
        showScheduleButton() {
            return (
                this.itemType === "business" &&
                !this.isMainProject &&
                this.jobFairActive
            )
        },
    },
    methods: {
        onResize() {
            if (!this.rows) return
            const container = this.$refs.grid
            if (!container) return
            this.numberOfCols = getComputedStyle(container)
                .getPropertyValue("grid-template-columns")
                .split(" ").length
            if (this.rows * this.numberOfCols > this.items.length) {
                this.$emit(
                    "fetch",
                    this.rows * this.numberOfCols -
                        this.items.length /* Number of missing entries */
                )
            }
        },
        /** v-bind to the corresponding component */
        getAttributes(item) {
            if (item.boundData) return item.boundData
            switch (this.itemType) {
                case "job":
                    return {
                        job: item,
                        fixedWidth: this.fixedWidth,
                        enableTouchSupport: true,
                        showTimestamp: true,
                    }
                case "business":
                    return {
                        business: item,
                        fixedWidth: this.fixedWidth,
                        searchResult: false,
                        targetBlank: false,
                    }
                case "post":
                    return {
                        post: item,
                        fixedWidth: this.fixedWidth,
                        searchResult: false,
                        targetBlank: false,
                    }
                default:
                    return {}
            }
        },
    },
}
