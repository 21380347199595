
import CardGridDynamic from "@/components/search/v2/cardGrids/CardGridDynamic"

import { Search } from "@/models/search"

const INLINE_ITEM_SPACING = 6

export default {
    name: "SearchResult",
    components: {
        CardGridDynamic,
        ProgressLinear: () => import("@/components/base/ProgressLinear"),
    },
    props: {
        /**
         * targetBlank parameter of the JobCard
         */
        targetBlank: {
            type: Boolean,
            default: false,
        },
        /** search object
         * String for legacy search (search category),
         */
        search: {
            type: [Search, String],
            validator: () => true,
        },
        /**
         * type of search
         * @values job, business
         * @default job
         */
        contentType: {
            type: String,
            default: "job",
            validator: (val) => ["job", "business", "post"].includes(val),
        },
        /** number of maximum rows */
        rows: {
            type: Number,
            default: undefined,
        },
        /** display loading state of the search (only initial loading) */
        loading: {
            type: Boolean,
            default: false,
        },
        /** sets custom result items list, disabled fetching */
        items: {
            type: Array,
            default: undefined,
        },
        customItems: {
            type: Array,
            default: undefined,
        },
        /** dark mode of the job cards */
        dark: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        isSearching: false,
        lists: [],
    }),
    computed: {
        searchItems() {
            if (this.items) return this.items
            if (!this.search) return []
            if (this.isLegacy) {
                const searchID = this.$store.getters["search/searchID"](
                    this.search
                )
                return this.$store.getters["search/search"](searchID).data
            }
            const items = [...this.search.data]
            if (this.customItems?.length > 0) {
                let inlineItemIndex = 2
                for (let i = 0; i < this.customItems.length; i++) {
                    items.splice(inlineItemIndex, 0, this.customItems[i])
                    inlineItemIndex += INLINE_ITEM_SPACING
                    if (inlineItemIndex >= items.length) break
                }
            }
            return items
        },
        isSearchAvailable() {
            if (this.isLegacy) return true
            return !!this.search
        },
        isLegacy() {
            return typeof this.search === "string"
        },
        totalNumberOfItems() {
            if (this.search?.meta?.total) {
                if (!this.customItems?.length)
                    return this.search.meta.total || 0
                const numberOfCustom = Math.ceil(
                    this.customItems.length / INLINE_ITEM_SPACING
                )
                return this.search.meta.total + parseInt(numberOfCustom)
            }
            return null
        },
    },
    methods: {
        onIntersection(_1, _2, isIntersecting) {
            if (this.items) return
            if (!this.isSearchAvailable) return
            if (isIntersecting) {
                return this.performSearch()
            }
        },
        async performSearch() {
            if (!this.isSearchAvailable) return
            this.isSearching = true
            if (this.isLegacy)
                await this.$store.dispatch("search/performSearch", {
                    category: this.search,
                })
            else await this.search.execute()
            this.isSearching = false
        },
        onFetch() {
            if (this.items) return
            this.performSearch()
        },
    },
}
